

/* Container holding the image and the text */
/* .wedding-card{
  background-image: url("../../resources/images/spring-floral-background-vector-illustration_zyCu56BO_SB_PM.jpg");
  background-repeat: round;
  background-position: 'center';
  background-size: 'cover';
  /* width: '100vw';
  height: '100vh' */
/* } */

.custom-text{
  font-family: cursive;
  color: rgb(0, 15, 100);
}

.container {
    position: relative;
    text-align: center;
    color: rgb(17, 1, 105);
  }
  
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }

  :root{
    --color1: #824b20;
    --color2: #e08027;
    --color3: #fff58f;
}
.loader{
    background: var(--color1);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 50px auto 0;
    position: relative;
    animation: expand 3s ease infinite;
    z-index: 1;
}
.loader:before,
.loader:after{
    content: "";
    background:var(--color2);
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    animation: expand_1 3s 300ms ease infinite;
}
.loader:after{
    background:var(--color3);
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    animation: expand_1 3s 600ms ease infinite;
}
@keyframes expand {
    0%,80%,100% { transform: scale(0); }
            40% { transform: scale(1); }
}
@keyframes expand_1 {
    0%,80%,100% { transform: translateX(-50%) translateY(-50%) scale(0); }
            40% { transform: translateX(-50%) translateY(-50%) scale(1); }
}