/* Portfolio.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .portfolio {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  main {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .profile-links ul {
    list-style-type: none;
    padding: 0;
  }
  
  .profile-links li {
    margin-bottom: 15px;
  }
  
  a {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  a:hover {
    color: #007bff;
  }

  /* SocialMediaLinks.css */

.social-media-links {
  text-align: center;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
