/* QRCodeStyles.css */

.qr-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; 
    max-width: 200px;
    padding: 20px;
    background-image: url("../../resources/images/qr_frame.png");
    background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  border-radius: 25px;
  }
  
  /* Style the QR code itself */
  .qr-cont svg {
    fill: #000; /* Change QR code color */
    /* Add other styles as needed */
  }
  