/* Apply your desired styles here */
.create-form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.create-form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='date'],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Apply your desired styles here */
.table-container {
  max-width: 800px;
  margin: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
}

button:hover {
  background-color: #0056b3;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff; /* Adjust as needed */
}

.checkbox{
  background: #273c75;
  width: 60px;
  height: 26px;
  margin: 0 auto;
  border-radius: 5px;
  position: relative;
}
.checkbox:before,
.checkbox:after{
  content: 'ON';
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.5px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 8px;
}
.checkbox:after{
  content: 'OFF';
  color: rgba(255,255,255,0.6);
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
  left: auto;
  right: 5px;
}   
.checkbox input[type=checkbox]{
  margin: 0;
  visibility: hidden;
  left: 7px;
  top: 7px;
}
.checkbox label{
  background: #fff;
  width: 25px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  transition: all 0.4s ease;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.toast-message {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.checkbox input[type=checkbox]:checked+label{ left: 32px; }
@media only screen and (max-width:767px){
  .checkbox{ margin: 0 0 20px;}}